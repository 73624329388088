.SingleDatePickerInput,
.DateInput,
.DateInput_input {
  border-radius: 4px;
}

.SingleDatePickerInput {
  border: 1px solid #d0dce6;
}

.DateInput_input {
  font-family: 'AkkuratStd', sans-serif;
  font-size: 1.6rem;
  border-bottom: 0;
  color: #16202d;
  height: 52px;
  padding: 0 16px;
}

.DateInput_input::placeholder {
  color: #8da3b5;
  font-family: 'AkkuratStd', sans-serif;
  font-size: 1.6rem;
}

.DateInput_input__focused {
  border-bottom: 0;
}

.SingleDatePickerInput_clearDate {
  padding: 0.5rem;
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #f4f5f5;
  border-radius: 0.5rem;
}

.SingleDatePickerInput_clearDate_svg {
  width: 10px;
  height: 10px;
}

.CalendarDay {
  vertical-align: middle;
}

.CalendarDay__default {
  border: 1px solid #d0dce6;
  color: #62707c;
  background: #fff;
}

.CalendarDay__default:hover {
  background: #f4f6f7;
  border: 1px solid #d0dce6;
  color: inherit;
}

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #d0dce6;
  color: inherit;
}

.DayPickerNavigation_button__default {
  border: 1px solid #d0dce6;
  background-color: #fff;
  color: #757575;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #ecf2f7;
  border: 1px solid #d0dce6;
  color: #8da3b5;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #243647;
  border-color: #243647;
  color: #fff;
}
